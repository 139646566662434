import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconArrowDownUp: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' {...props}>
      <path
        d='M2 10.667L4.66667 13.3337M4.66667 13.3337L7.33333 10.667M4.66667 13.3337V2.66699M14 5.33366L11.3333 2.66699M11.3333 2.66699L8.66667 5.33366M11.3333 2.66699V13.3337'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
