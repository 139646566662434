import {
  DeleteOpenQuestionsDto,
  GetAllPassedOpenQuestionsByUserDto,
  GetAllPassedOpenQuestionsByUserResponse,
  GetOpenQuestionsDto,
  GetOpenQuestionsResponse,
  SetOpenQuestionsDto,
  UpdateOpenQuestionDto,
  UpdateOpenQuestionsDto
} from 'models/open-question';
import { instance } from './instance';

export const getAllPassedOpenQuestionsByUser = async ({
  userId
}: GetAllPassedOpenQuestionsByUserDto) => {
  const response = await instance.get<GetAllPassedOpenQuestionsByUserResponse>(
    `open_questions/result/supervisor/${userId}`
  );
  return response.data;
};

export const getOpenQuestions = async ({ testId }: GetOpenQuestionsDto) => {
  const response = await instance.get<GetOpenQuestionsResponse>(`open_questions/${testId}`);
  return response.data;
};

export const setOpenQuestions = async ({ testId, body }: SetOpenQuestionsDto) => {
  const response = await instance.post<GetOpenQuestionsResponse>(`open_questions/${testId}`, body);
  return response.data;
};

export const updateOpenQuestions = async ({ testId, body }: UpdateOpenQuestionsDto) => {
  const response = await instance.put<GetOpenQuestionsResponse>(`open_questions/${testId}`, body);
  return response.data;
};

export const updateOpenQuestion = async ({ testId, questionId, body }: UpdateOpenQuestionDto) => {
  const response = await instance.patch<GetOpenQuestionsResponse>(
    `open_questions/${testId}?questionId=${questionId}`,
    body
  );
  return response.data;
};

export const deleteOpenQuestions = async ({ testId }: DeleteOpenQuestionsDto) => {
  const response = await instance.delete(`open_questions/${testId}`);
  return response.data;
};
