import {
  GetAllPassedFormsByUserDto,
  GetAllPassedFormsByUserResponse,
  GetTestFormQuestionsDto,
  GetTestFormQuestionsResponse,
  SetTestFormQuestionsDto
} from 'models/test-form';
import { instance } from './instance';

export const getAllPassedFormsByUser = async ({ userId }: GetAllPassedFormsByUserDto) => {
  const response = await instance.get<GetAllPassedFormsByUserResponse>(
    `forms/result/supervisor/${userId}`
  );
  return response.data;
};

export const getTestFormQuestions = async ({ testId }: GetTestFormQuestionsDto) => {
  const response = await instance.get<GetTestFormQuestionsResponse>(`forms/${testId}`);
  return response.data;
};

export const setTestFormQuestions = async ({ testId, body }: SetTestFormQuestionsDto) => {
  const response = await instance.post<GetTestFormQuestionsResponse>(`forms/${testId}`, body);
  return response.data;
};

export const updateTestFormQuestions = async ({ testId, body }: SetTestFormQuestionsDto) => {
  const response = await instance.put<GetTestFormQuestionsResponse>(`forms/${testId}`, body);
  return response.data;
};

export const deleteTestFormQuestions = async ({ testId }: GetTestFormQuestionsDto) => {
  const response = await instance.delete(`forms/${testId}`);
  return response.data;
};
