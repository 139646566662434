import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getUserTestingsByTestId } from 'api/testing';
import { handleError } from 'helpers';
import { GetUserTestingsByTestIdDto, GetUserTestingsByTestIdResponse } from 'models/testing';

export const useGetUserTestingsByTestIdQuery = (dto: GetUserTestingsByTestIdDto) => {
  return useQuery<GetUserTestingsByTestIdResponse, AxiosError>(
    ['testing/table_mapper'],
    () => getUserTestingsByTestId(dto),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
