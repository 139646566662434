import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getTestingsByTestId } from 'api/testing';
import { handleError } from 'helpers';
import { GetTestingsByTestIdDto, GetTestingsByTestIdResponse } from 'models/testing';

export const useGetTestingsByTestIdQuery = (dto: GetTestingsByTestIdDto) => {
  return useQuery<GetTestingsByTestIdResponse, AxiosError>(
    [`testing/supervisor/all_testings_in_company/${dto.testId}`],
    () => getTestingsByTestId(dto),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
