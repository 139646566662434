import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getUserTesting } from 'api/testing';
import { handleError } from 'helpers';
import { GetUserTestingDto, GetUserTestingResponse } from 'models/testing';

export const useGetUserTestingQuery = (dto: GetUserTestingDto) => {
  return useQuery<GetUserTestingResponse, AxiosError>(
    ['testing/supervisor/testing', dto.testingId, dto.userId],
    () => getUserTesting(dto),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
