import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconArrowUp: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' {...props}>
      <path
        d='M10.6663 5.33366L7.99967 2.66699M7.99967 2.66699L5.33301 5.33366M7.99967 2.66699V13.3337'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
