export type TestFormQuestion = {
  question: string;
  answerOptions: string[];
  formQuestionAnswerType: FormQuestionAnswerType;
  toProfileParam: string;
};

export type CreateTestFormQuestion = {
  question: string;
  // TODO expand the object when saving
  answerOptions: { value: string }[];
  formQuestionAnswerType: FormQuestionAnswerType;
  toProfileParam: string;
};

export enum FormQuestionAnswerType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  TEXT = 'text'
}

export type GetAllPassedFormsByUserDto = {
  userId: string;
};

export type GetAllPassedFormsByUserResponse = {
  testId: string;
  name: string;
  passedDate: string;
  duration: number;
  answers: {
    question: string;
    answer: string[];
    toProfileParam: string;
  }[];
}[];

export type GetTestFormQuestionsDto = {
  testId: string;
};

export type GetTestFormQuestionsResponse = TestFormQuestion[];

export type SetTestFormQuestionsDto = {
  testId: string;
  refetch?: boolean;
  body: TestFormQuestion[];
};
