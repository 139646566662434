import { FC } from 'react';

import { useGetAllPassedFormsByUserQuery } from 'hooks/test-form';

type TestingResultFormProps = {
  userId: string;
  testId: string;
  className?: string;
};

export const TestingResultForm: FC<TestingResultFormProps> = props => {
  const { testId, userId, className } = props;
  const { data: passedForms, isLoading } = useGetAllPassedFormsByUserQuery({ userId });

  const passedForm = passedForms?.find(passedForm => passedForm.testId === testId);

  if (isLoading) return <div>Загрузка...</div>;

  if (!passedForms || !passedForm) return <div>Не удалось загрузить результаты</div>;

  const { answers } = passedForm;

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='h-[56px] mb-[24px] flex items-center'>
        <h3 className='text-h3 text-on-surface'>Результаты прохождения анкеты</h3>
      </header>

      <ul className='mb-[40px] flex flex-col gap-[16px]'>
        {answers.map((answer, index) => (
          <li key={index} className='flex flex-col gap-[8px]'>
            <h4 className='text-h4 text-on-surface'>{answer.question}</h4>
            <p className='text-body text-on-surface'>{answer.answer.join(', ')}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
