import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getAllPassedFormsByUser } from 'api/test-form';
import { handleError } from 'helpers';
import { GetAllPassedFormsByUserDto, GetAllPassedFormsByUserResponse } from 'models/test-form';

export const useGetAllPassedFormsByUserQuery = ({ userId }: GetAllPassedFormsByUserDto) => {
  return useQuery<GetAllPassedFormsByUserResponse, AxiosError>(
    [`forms/result/supervisor/${userId}`],
    () => getAllPassedFormsByUser({ userId }),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
