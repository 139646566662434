import { useMutation, useQueryClient } from 'react-query';

import { apiTest } from 'api/test';
import { TESTS_QUERY_KEY } from 'const/test';
import { handleError, notifySuc } from 'helpers';
import { CopyTestDto, GetTestsResponse } from 'models/test(old)';

export const useCopyTestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CopyTestDto) => {
      return apiTest.copyTest(data);
    },
    {
      onSuccess(createdTest) {
        notifySuc('Тест скопирован');

        queryClient.invalidateQueries(TESTS_QUERY_KEY, {
          refetchActive: false,
          refetchInactive: false
        });

        const oldData = queryClient.getQueryData<GetTestsResponse>(TESTS_QUERY_KEY);
        if (oldData) {
          const updatedData = [...oldData, createdTest];
          queryClient.setQueryData(TESTS_QUERY_KEY, updatedData);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
