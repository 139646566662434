import { FC } from 'react';

import { useGetAllPassedOpenQuestionsByUserQuery } from 'hooks/open-question';

type TestingResultOpenQuestionsProps = {
  userId: string;
  testId: string;
  className?: string;
};

export const TestingResultOpenQuestions: FC<TestingResultOpenQuestionsProps> = props => {
  const { testId, userId, className } = props;
  const { data: passedOpenQuestions, isLoading } = useGetAllPassedOpenQuestionsByUserQuery({
    userId
  });

  const passedOpenQuestion = passedOpenQuestions?.find(passedForm => passedForm.testId === testId);

  if (isLoading) return <div>Загрузка...</div>;

  if (!passedOpenQuestions || !passedOpenQuestion)
    return <div>Не удалось загрузить результаты</div>;

  const { answers } = passedOpenQuestion;

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='h-[56px] mb-[24px] flex items-center'>
        <h3 className='text-h3 text-on-surface'>Результаты прохождения открытых вопросов</h3>
      </header>

      <ul className='mb-[40px] flex flex-col gap-[16px]'>
        {answers.map((answer, index) => (
          <li key={index} className='flex flex-col gap-[8px]'>
            <h4 className='text-h4 text-on-surface'>{answer.question}</h4>
            <p className='text-body text-on-surface'>{answer.answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
