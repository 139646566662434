import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Portal, TextField } from 'components';
import { DEFAULT_ANIMATION_DELAY } from 'const/animation';
import { FORM_ERROR_MESSAGES } from 'const/form';
import { useUserRole } from 'hooks/auth';
import { useCopyTestMutation } from 'hooks/test';
import { USER_ROLES } from 'models/user';

type FormValues = {
  testName: string;
  companyId?: string;
};

interface CopyTestModalProps {
  testId: string;
  isOpen: boolean;
  onClose: () => void;
  lazy?: boolean;
  className?: string;
}

export const CopyTestModal: FC<CopyTestModalProps> = props => {
  const { testId, isOpen, onClose, lazy = true, className } = props;
  const [isClosing, setIsClosing] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const viewerRole = useUserRole();
  const { mutate: copyTestMutate, isLoading: copyTestLoading } = useCopyTestMutation();

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    timerRef.current = setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, DEFAULT_ANIMATION_DELAY);
  }, [onClose]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
      document.body.classList.add('overflow-hidden');
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      document.body.classList.remove('overflow-hidden');
      clearTimeout(timerRef.current);
    };
  }, [isOpen, onKeyDown]);

  const isAdmin = useMemo(() => viewerRole === USER_ROLES.ADMIN, [viewerRole]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid }
  } = useForm<FormValues>({
    mode: 'onSubmit'
  });

  const onSubmit = async (data: FormValues) => {
    copyTestMutate(
      { testId, ...data },
      {
        onSuccess: () => {
          setIsClosing(true);
          timerRef.current = setTimeout(() => {
            setIsClosing(false);
          }, DEFAULT_ANIMATION_DELAY);
        }
      }
    );
  };

  const handleReset = () => {
    reset();
  };

  if (lazy && !isMounted) {
    return null;
  }

  return (
    <Portal>
      <div
        className={
          'fixed top-0 right-0 bottom-0 left-0 flex ' +
          'justify-center items-center transition-all duration-300 ease-in-out ' +
          `${
            isClosing
              ? 'opacity-0 z-[var(--z-index-hidden)] invisible pointer-events-none'
              : isOpen
              ? 'opacity-100 z-[var(--z-index-modal)] visible pointer-events-auto'
              : 'opacity-0 z-[var(--z-index-hidden)] invisible pointer-events-none'
          }`
        }
      >
        <div
          className={
            'h-full w-full flex justify-center items-center bg-[var(--color-overlay)] cursor-pointer'
          }
          onClick={handleClose}
        >
          <div
            className={
              'max-h-[90vh] mx-[20px] p-[40px] flex flex-col ' +
              'bg-surface rounded-[16px] elevation-5 overflow-y-auto overflow-x-hidden ' +
              'transition-transform duration-300 ease-in-out ' +
              `${isClosing ? 'scale-[0.6]' : isOpen ? 'scale-[1]' : 'scale-[0.6]'} ` +
              className
            }
            onClick={event => event.stopPropagation()}
          >
            <header className='mb-[36px] flex'>
              <h3 className='text-h3 text-on-surface'>Копировать тест</h3>
            </header>

            <form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-[36px] flex flex-col gap-[16px]'>
                <TextField
                  name='testName'
                  label='Название нового теста'
                  control={control}
                  rules={{
                    required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                    maxLength: { value: 64, message: 'Максимальная длина - 64 символа' }
                  }}
                  disabled={copyTestLoading}
                />

                {isAdmin && (
                  <TextField
                    name='companyId'
                    label='ID компании'
                    control={control}
                    rules={{
                      required: { value: false, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                      maxLength: { value: 64, message: 'Максимальная длина - 64 символа' }
                    }}
                    disabled={copyTestLoading}
                  />
                )}
              </div>

              <div className='flex self-end gap-[8px]'>
                <Button
                  type='reset'
                  variant='secondary'
                  title='Сбросить'
                  className='min-w-[160px]'
                  onClick={handleReset}
                  isDisabled={!isDirty || copyTestLoading}
                />
                <Button
                  type='submit'
                  variant='primary'
                  title='Копировать'
                  className='min-w-[160px]'
                  isDisabled={!isDirty || !isValid}
                  isLoading={copyTestLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Portal>
  );
};
