import { CreateOpenQuestionDto } from 'models/open-question';
import { CreateGroupedQuestionDto, GroupedQuestion } from 'models/question(old)';
import { CreateTestFormQuestion } from 'models/test-form';
import { MediaDTO } from './media';

export type Test = {
  _id: string;
  name: string;
  mediaDTO?: MediaDTO;
  desc: string;
  // Длительность одного вопроса или всех вместе?
  duration: number;
  // Старое поле? Зачем?
  reward: number;
  groupedQuestionsCollectionId: string;
  formCollectionId: string;
  openQuestionsCollectionId: string;
  companyId?: string;
  visibility: TestVisibility;
  createdAt: string;
  updatedAt: string;
  // Нет gratitude
  // Нет testType
};

export enum TestVisibility {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export type GetTestsResponse = Test[];

export type CreateTestDto = {
  body: {
    name: string;
    mediaDTO?: MediaDTO;
    desc: string;
    duration: number;
    reward: number;
    visibility: TestVisibility;
  };
};

export type UpdateTestDto = {
  testId: string;
  refetch?: boolean;
  body: {
    name?: string;
    mediaDTO?: MediaDTO;
    desc?: string;
    duration?: number;
    reward?: number;
    visibility?: TestVisibility;
  };
};

export type GetTestQuestionsDto = {
  testId: string;
};

export type GetTestQuestionsResponse = GroupedQuestion[];

export type SetTestQuestionsDto = {
  testId: string;
  refetch?: boolean;
  body: CreateGroupedQuestionDto[];
};

export type TestFormValues = CreateTestDto['body'] & {
  groupedQuestions: CreateGroupedQuestionDto[];
  openQuestions: CreateOpenQuestionDto[];
  formQuestions: CreateTestFormQuestion[];
  promocode?: string;
};

export type AddTestFormQuestionFormValues = CreateTestFormQuestion;

export type DeleteTestDto = { testId: string };

export type CopyTestDto = {
  testId: string;
  // new test name
  testName: string;
  // for admin
  companyId?: string;
};
