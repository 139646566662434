import { FC } from 'react';

import { Button, IconChevronLeft, IconCross } from 'components';

// const options = [
//   { id: 1, label: 'Item 1' },
//   { id: 2, label: 'Item 2' },
//   { id: 3, label: 'Item 3' },
//   { id: 4, label: 'Item 4' }
// ];

type FiltersProps = {
  className?: string;
};

const Filters: FC<FiltersProps> = props => {
  const { className } = props;

  return (
    <div className={`flex flex-wrap gap-[8px] ${className}`}>
      {/* <Filter placeholder='Filter' options={options} /> */}

      <div
        className={
          'flex gap-[8px] items-center p-[8px_12px_8px_16px] ' +
          'rounded-[12px] bg-light elevation-2 ' +
          'select-none opacity-70'
        }
      >
        <span className='text-ts text-on-surface-variant'>Цвет глаз</span>
        <IconChevronLeft className='text-on-surface-variant h-[24px] w-[24px] -rotate-90' />
      </div>

      <div
        className={
          'flex gap-[8px] items-center p-[8px_12px_8px_16px] ' +
          'rounded-[12px] bg-light elevation-2 ' +
          'select-none opacity-70'
        }
      >
        <span className='text-ts text-on-surface-variant'>Семейное положение</span>
        <IconChevronLeft className='text-on-surface-variant h-[24px] w-[24px] -rotate-90' />
      </div>

      <div
        className={
          'flex gap-[8px] items-center p-[8px_12px_8px_16px] ' +
          'rounded-[12px] bg-light elevation-2 ' +
          'select-none opacity-70'
        }
      >
        <span className='text-ts text-on-surface-variant'>Пол</span>
        <IconChevronLeft className='text-on-surface-variant h-[24px] w-[24px] -rotate-90' />
      </div>

      <Button
        variant='transparent'
        height='small'
        title='Сбросить фильтры'
        icon={<IconCross className='h-[18px] w-[18px]' />}
        disabled
      />
    </div>
  );
};

export default Filters;
