import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getAllPassedOpenQuestionsByUser } from 'api/open-questions';
import { handleError } from 'helpers';
import {
  GetAllPassedOpenQuestionsByUserDto,
  GetAllPassedOpenQuestionsByUserResponse
} from 'models/open-question';

export const useGetAllPassedOpenQuestionsByUserQuery = ({
  userId
}: GetAllPassedOpenQuestionsByUserDto) => {
  return useQuery<GetAllPassedOpenQuestionsByUserResponse, AxiosError>(
    [`forms/result/supervisor/${userId}`],
    () => getAllPassedOpenQuestionsByUser({ userId }),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
