import { GetTestQuestionsResponse } from 'models/test(old)';
import { AnswerResult, FeedbackResult, GroupedAnswersResult, Testing } from 'models/testing';

export const getAverageGroupedAnswers = (
  averageScore: number,
  testings: Testing[],
  testQuestions?: GetTestQuestionsResponse
): GroupedAnswersResult[] => {
  if (!testings || !testQuestions) return [];

  const allInnerAnswers = testings
    .map(testing => testing.groupedAnswers)
    .flat()
    .filter(
      groupedAnswer =>
        !groupedAnswer.innerAnswers.every(innerAnswer => innerAnswer.questionType === 'neutral')
    )
    .map(groupedAnswer => groupedAnswer.innerAnswers)
    .flat();

  const firstTesting = testings[0];

  const groupedAnswers = firstTesting.groupedAnswers
    .filter(
      groupedAnswer =>
        !groupedAnswer.innerAnswers.every(innerAnswer => innerAnswer.questionType === 'neutral')
    )
    .map(groupedAnswer => {
      const feedbackUser =
        testQuestions
          ?.find(
            group =>
              group.groupedQuestionUUID === groupedAnswer.groupedQuestionUUID ||
              group.question === groupedAnswer.question
          )
          ?.feedback?.feedbackUser?.find(
            ({ min, max }) => min <= averageScore && max >= averageScore
          )?.feedback || '';

      const feedbackMasterManager =
        testQuestions
          ?.find(
            group =>
              group.groupedQuestionUUID === groupedAnswer.groupedQuestionUUID ||
              group.question === groupedAnswer.question
          )
          ?.feedback?.feedbackMasterManager?.find(
            ({ min, max }) => min <= averageScore && max >= averageScore
          )?.feedback || '';

      const feedbackCompany =
        testQuestions
          ?.find(
            group =>
              group.groupedQuestionUUID === groupedAnswer.groupedQuestionUUID ||
              group.question === groupedAnswer.question
          )
          ?.feedback?.feedbackCompany?.find(
            ({ min, max }) => min <= averageScore && max >= averageScore
          )?.feedback || '';

      const recommendationUser =
        testQuestions
          ?.find(
            group =>
              group.groupedQuestionUUID === groupedAnswer.groupedQuestionUUID ||
              group.question === groupedAnswer.question
          )
          ?.feedback?.recommendationUser?.find(
            ({ min, max }) => min <= averageScore && max >= averageScore
          )?.feedback || '';

      const recommendationMasterManager =
        testQuestions
          ?.find(
            group =>
              group.groupedQuestionUUID === groupedAnswer.groupedQuestionUUID ||
              group.question === groupedAnswer.question
          )
          ?.feedback?.recommendationMasterManager?.find(
            ({ min, max }) => min <= averageScore && max >= averageScore
          )?.feedback || '';

      const recommendationCompany =
        testQuestions
          ?.find(
            group =>
              group.groupedQuestionUUID === groupedAnswer.groupedQuestionUUID ||
              group.question === groupedAnswer.question
          )
          ?.feedback?.recommendationCompany?.find(
            ({ min, max }) => min <= averageScore && max >= averageScore
          )?.feedback || '';

      const feedbackResult: FeedbackResult = {
        feedbackUser,
        feedbackMasterManager,
        feedbackCompany,
        recommendationUser,
        recommendationMasterManager,
        recommendationCompany
      };

      const innerAnswers: AnswerResult[] = groupedAnswer.innerAnswers.map(innerAnswer => {
        const feedbackUser =
          testQuestions
            ?.find(group =>
              group.innerQuestions?.find(
                q =>
                  q.questionUUID === innerAnswer.questionUUID || q.question === innerAnswer.question
              )
            )
            ?.feedback?.feedbackUser?.find(
              ({ min, max }) => min <= averageScore && max >= averageScore
            )?.feedback || '';

        const feedbackMasterManager =
          testQuestions
            ?.find(group =>
              group.innerQuestions?.find(
                q =>
                  q.questionUUID === innerAnswer.questionUUID || q.question === innerAnswer.question
              )
            )
            ?.feedback?.feedbackMasterManager?.find(
              ({ min, max }) => min <= averageScore && max >= averageScore
            )?.feedback || '';

        const feedbackCompany =
          testQuestions
            ?.find(group =>
              group.innerQuestions?.find(
                q =>
                  q.questionUUID === innerAnswer.questionUUID || q.question === innerAnswer.question
              )
            )
            ?.feedback?.feedbackCompany?.find(
              ({ min, max }) => min <= averageScore && max >= averageScore
            )?.feedback || '';

        const recommendationUser =
          testQuestions
            ?.find(group =>
              group.innerQuestions?.find(
                q =>
                  q.questionUUID === innerAnswer.questionUUID || q.question === innerAnswer.question
              )
            )
            ?.feedback?.recommendationUser?.find(
              ({ min, max }) => min <= averageScore && max >= averageScore
            )?.feedback || '';

        const recommendationMasterManager =
          testQuestions
            ?.find(group =>
              group.innerQuestions?.find(
                q =>
                  q.questionUUID === innerAnswer.questionUUID || q.question === innerAnswer.question
              )
            )
            ?.feedback?.recommendationMasterManager?.find(
              ({ min, max }) => min <= averageScore && max >= averageScore
            )?.feedback || '';

        const recommendationCompany =
          testQuestions
            ?.find(group =>
              group.innerQuestions?.find(
                q =>
                  q.questionUUID === innerAnswer.questionUUID || q.question === innerAnswer.question
              )
            )
            ?.feedback?.recommendationCompany?.find(
              ({ min, max }) => min <= averageScore && max >= averageScore
            )?.feedback || '';

        const feedbackResult: FeedbackResult = {
          feedbackUser,
          feedbackMasterManager,
          feedbackCompany,
          recommendationUser,
          recommendationMasterManager,
          recommendationCompany
        };

        const innerAnswerVariants = allInnerAnswers.filter(
          i => i.questionUUID === innerAnswer.questionUUID || i.question === innerAnswer.question
        );

        const sum = innerAnswerVariants.reduce((acc, innerAnswer) => {
          if (
            !innerAnswer.correlationResultAverageXYZ ||
            !innerAnswer.correlationResultAverageXYZ.accelerometer
          )
            return acc;
          return innerAnswer.correlationResultAverageXYZ.accelerometer + acc;
        }, 0);

        const questionAmount = innerAnswerVariants.length;

        const averageAccelerometer = sum / questionAmount;

        return {
          ...innerAnswer,
          feedbackResult,
          correlationResultAverageXYZ: {
            delay: innerAnswer.correlationResultAverageXYZ?.delay || 0,
            gyroscope: innerAnswer.correlationResultAverageXYZ?.gyroscope || 0,
            accelerometer: averageAccelerometer ? Math.round(averageAccelerometer) : 0
          }
        };
      });

      return {
        ...groupedAnswer,
        feedbackResult,
        innerAnswers
      };
    });

  return groupedAnswers;
};
