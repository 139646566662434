import { useGetAccount } from 'hooks/account';
import { useGetAllPassedFormsByUserQuery } from 'hooks/test-form';
import { FC } from 'react';

type TabFormProps = {
  testId: string;
  userIds: string[];
  className?: string;
};

export const TabForm: FC<TabFormProps> = props => {
  const { testId, userIds, className } = props;

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='h-[56px] mb-[24px] flex items-center'>
        <h3 className='text-h3 text-on-surface'>Результаты прохождения анкеты</h3>
      </header>

      <table>
        {[userIds[0], ...userIds].map((userId, index) =>
          index === 0 ? (
            <ItemHeader key={userId} testId={testId} userId={userId} />
          ) : (
            <ItemRow key={userId} testId={testId} userId={userId} />
          )
        )}
      </table>
    </div>
  );
};

type ItemProps = {
  testId: string;
  userId: string;
  className?: string;
};

export const ItemHeader: FC<ItemProps> = props => {
  const { testId, userId, className } = props;
  const { data: passedForms, isLoading } = useGetAllPassedFormsByUserQuery({ userId });

  const passedForm = passedForms?.find(passedForm => passedForm.testId === testId);

  if (isLoading) return <div>Загрузка...</div>;

  if (!passedForms || !passedForm) return <div>Не удалось загрузить данные</div>;

  const { answers } = passedForm;

  return (
    <tr className={className}>
      <th className='text-start text-on-surface'>Имя</th>
      {answers.map((answer, index) => (
        <th key={index} className='text-start text-on-surface'>
          {answer.question}
        </th>
      ))}
    </tr>
  );
};

export const ItemRow: FC<ItemProps> = props => {
  const { testId, userId, className } = props;
  const { data: passedForms, isLoading } = useGetAllPassedFormsByUserQuery({ userId });
  const { data: user, isLoading: isLoadingUser } = useGetAccount({ userId });

  const passedForm = passedForms?.find(passedForm => passedForm.testId === testId);

  if (isLoading || isLoadingUser) return <div>Загрузка...</div>;

  if (!passedForms || !passedForm || !user) return <div>Не удалось загрузить данные</div>;

  const { answers } = passedForm;
  const { name } = user;

  return (
    <tr className={className}>
      <td className='text-start text-on-surface'>{name}</td>
      {answers.map((answer, index) => (
        <td key={index} className='text-start text-on-surface'>
          {answer.answer.join(', ')}
        </td>
      ))}
    </tr>
  );
};
