import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LogoSmall from 'assets/images/svg/ineo-logo-small.svg';

import { AdminPaths, AdminRoutes } from 'const/routes';
import { useGetPromocodes } from 'hooks/promocode';
import { useDeleteTestMutation } from 'hooks/test';
import { allocateTime, copyText } from 'lib';
import { Test, TestVisibility } from 'models/test(old)';
import styles from './TestCard.module.css';

import { ButtonIcon, IconCopy, IconEdit, IconTrash, ModalConfirm, TimeSmall } from 'components';
import { CopyTestModal } from 'features/copy-test';

type TestCardProps = {
  test: Test;
  className?: string;
};

const TestCard: FC<TestCardProps> = props => {
  const {
    test: { _id, name, desc, duration, visibility },
    className
  } = props;
  const navigate = useNavigate();
  const { data: promocodes } = useGetPromocodes();
  const { mutate: deleteTestMutate, isLoading: deleteLoading } = useDeleteTestMutation();
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const promocode = useMemo<string | undefined>(
    () => promocodes?.reverse().find(promocode => promocode.availableTestsIds.includes(_id))?.code,
    [promocodes, _id]
  );

  const handleCopyCode = () => {
    if (!promocode) return;
    copyText(promocode);
  };

  const goToTestPage = () => {
    navigate(`${AdminPaths[AdminRoutes.TESTS]}/${_id}`);
  };

  const handleCopyTest = () => {
    setCopyModalOpen(true);
  };

  const handleRemove = () => {
    setDeleteModalOpen(true);
  };

  const confirmRemove = () => {
    deleteTestMutate({ testId: _id });
  };

  return (
    <>
      <li
        className={
          'p-[12px_8px_24px_24px] ' +
          'flex flex-col rounded-[20px] bg-light elevation-2 ' +
          className
        }
      >
        <header className='flex justify-between items-center gap-[16px] mb-[4px]'>
          <img src={LogoSmall} alt={name} className='max-w-[50px] w-full' />
          <div className='flex gap-[8px]'>
            <ButtonIcon Icon={IconEdit} variant='transparent' size='big' onClick={goToTestPage} />
            <ButtonIcon Icon={IconCopy} variant='transparent' size='big' onClick={handleCopyTest} />
            <ButtonIcon Icon={IconTrash} variant='transparent' size='big' onClick={handleRemove} />
          </div>
        </header>

        <h3 className='text-h3 text-primary mb-[8px]'>{name}</h3>
        <p className={styles.desc + ' mb-[16px] text-bm text-on-surface-variant'}>{desc}</p>
        {promocode && (
          <div className='mb-[8px] flex gap-[8px] items-center'>
            <p className={'text-tm text-on-surface-variant'}>{promocode}</p>
            <ButtonIcon Icon={IconCopy} variant='transparent' onClick={handleCopyCode} />
          </div>
        )}

        <ul className='flex flex-wrap gap-[8px]'>
          <TimeSmall time={allocateTime(duration * 60)} />
          <li
            className={
              'h-[32px] p-[8px_16px_8px_16px] flex items-center gap-[8px] ' +
              'bg-primary-container rounded-[28px]'
            }
          >
            <span className='text-ll text-on-primary-fixed'>
              {visibility === TestVisibility.PUBLIC ? 'Публичный' : 'Приватный'}
            </span>
          </li>
        </ul>
      </li>

      <CopyTestModal testId={_id} isOpen={copyModalOpen} onClose={() => setCopyModalOpen(false)} />

      <ModalConfirm
        title='Удалить тест?'
        confirmText='Удалить'
        isOpen={deleteModalOpen}
        portal
        isLoading={deleteLoading}
        onConfirm={confirmRemove}
        onClose={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default TestCard;
