import { useGetAccount } from 'hooks/account';
import { useGetAllPassedOpenQuestionsByUserQuery } from 'hooks/open-question';
import { FC } from 'react';

type TabOpenQuestionsProps = {
  testId: string;
  userIds: string[];
  className?: string;
};

export const TabOpenQuestions: FC<TabOpenQuestionsProps> = props => {
  const { testId, userIds, className } = props;

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='h-[56px] mb-[24px] flex items-center'>
        <h3 className='text-h3 text-on-surface'>Результаты прохождения анкеты</h3>
      </header>

      <table>
        {[userIds[0], ...userIds].map((userId, index) =>
          index === 0 ? (
            <ItemHeader key={userId} testId={testId} userId={userId} />
          ) : (
            <ItemRow key={userId} testId={testId} userId={userId} />
          )
        )}
      </table>
    </div>
  );
};

type ItemProps = {
  testId: string;
  userId: string;
  className?: string;
};

export const ItemHeader: FC<ItemProps> = props => {
  const { testId, userId, className } = props;
  const { data: passedOpenQuestions, isLoading } = useGetAllPassedOpenQuestionsByUserQuery({
    userId
  });

  const passedOpenQuestion = passedOpenQuestions?.find(
    passedOpenQuestion => passedOpenQuestion.testId === testId
  );

  if (isLoading) return <div>Загрузка...</div>;

  if (!passedOpenQuestions || !passedOpenQuestion) return <div>Не удалось загрузить данные</div>;

  const { answers } = passedOpenQuestion;

  return (
    <tr className={className}>
      <th className='text-start text-on-surface'>ID</th>
      <th className='text-start text-on-surface'>Имя</th>
      {answers.map((answer, index) => (
        <th key={index} className='text-start text-on-surface'>
          {answer.question}
        </th>
      ))}
    </tr>
  );
};

export const ItemRow: FC<ItemProps> = props => {
  const { testId, userId, className } = props;
  const { data: passedOpenQuestions, isLoading } = useGetAllPassedOpenQuestionsByUserQuery({
    userId
  });
  const { data: user, isLoading: isLoadingUser } = useGetAccount({ userId });

  const passedOpenQuestion = passedOpenQuestions?.find(
    passedOpenQuestion => passedOpenQuestion.testId === testId
  );

  if (isLoading || isLoadingUser) return <div>Загрузка...</div>;

  if (!passedOpenQuestions || !passedOpenQuestion || !user)
    return <div>Не удалось загрузить данные</div>;

  const { answers } = passedOpenQuestion;
  const { name } = user;

  return (
    <tr className={className}>
      <td className='text-start text-on-surface'>{userId}</td>
      <td className='text-start text-on-surface'>{name}</td>
      {answers.map((answer, index) => (
        <td key={index} className='text-start text-on-surface'>
          {answer.answer}
        </td>
      ))}
    </tr>
  );
};
