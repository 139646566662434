import { FC, useMemo, useState } from 'react';

import {
  Button,
  ButtonIcon,
  Calibration,
  GroupedAnswer,
  IconCross,
  IconDownload,
  Ratio
} from 'components';
import { getTestingScore } from 'lib';
import { Testing } from 'models/testing';
import { Tabs } from './Tabs';
import { TestingResultForm } from './TestingResultForm';
import { TestingResultOpenQuestions } from './TestingResultOpenQuestions';

type TestingResultProps = {
  testing: Testing;
  forWhom?: 'master' | 'company';
  onClose: () => void;
  className?: string;
};

const TestingResult: FC<TestingResultProps> = props => {
  const {
    testing,
    testing: { name, groupedAnswers, testId, userId },
    forWhom,
    onClose
  } = props;
  const [show, setShow] = useState<
    'feedback' | 'recommendation' | 'calibration' | 'form' | 'open-questions'
  >('feedback');
  const testingScore = useMemo(() => getTestingScore(testing), [testing]);

  const groupedAnswersWithoutNeutral = useMemo(() => {
    return groupedAnswers.filter(
      groupedAnswer =>
        !groupedAnswer.innerAnswers.every(innerAnswer => innerAnswer.questionType === 'neutral')
    );
  }, [groupedAnswers]);

  return (
    <div className='grow flex flex-col'>
      <header className='mb-[36px] flex justify-between gap-[8px] items-center'>
        <h3 className='text-h3 text-on-surface'>Результат теста</h3>

        <div className='flex items-center gap-[40px]'>
          <Button
            variant='primary'
            height='small'
            title='Скачать в .pdf'
            icon={<IconDownload className='h-[18px] w-[18px]' />}
            iconPosition='right'
            isDisabled
          />
          <ButtonIcon Icon={IconCross} variant='transparent' size='big' onClick={onClose} />
        </div>
      </header>

      <div className='mb-[36px] flex justify-between items-center gap-[8px]'>
        <div className='flex flex-col gap-[8px]'>
          <h4 className='text-h4 text-tertiary'>Тест:</h4>
          <h3 className='text-h3 text-on-surface'>{name}</h3>
        </div>
        <Ratio value={testingScore} />
      </div>

      <Tabs show={show} setShow={setShow} className='mb-[36px]' />

      {!!groupedAnswersWithoutNeutral.length &&
        show !== 'calibration' &&
        show !== 'form' &&
        show !== 'open-questions' && (
          <ul className='flex flex-col gap-[36px]'>
            {groupedAnswersWithoutNeutral.map((groupedAnswer, index) => (
              <div key={groupedAnswer.groupedQuestionUUID} className='flex flex-col gap-[24px]'>
                <GroupedAnswer
                  key={index}
                  groupedAnswer={groupedAnswer}
                  calibration={testing.calibration}
                  show={show}
                  order={index + 1}
                  forWhom={forWhom}
                  className={index === groupedAnswersWithoutNeutral.length - 1 ? 'mb-[40px]' : ''}
                />
              </div>
            ))}
          </ul>
        )}

      {show === 'calibration' && <Calibration calibration={testing.calibration} />}

      {show === 'form' && <TestingResultForm userId={userId} testId={testId} />}

      {show === 'open-questions' && <TestingResultOpenQuestions userId={userId} testId={testId} />}
    </div>
  );
};

export default TestingResult;
